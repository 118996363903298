import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Nav } from '../../components';
import { WorkSectionHeader } from '../../components';
import GlobalLayout from '../../layouts/GlobalLayout';

const Bank = () => {
  const imgStyleOutline = {
    boxShadow: '0px 4px 32px #00000015',
    maxWidth: '1280px',
    placeSelf: 'center',
    border: '1px solid black',
    marginTop: '3.33em',
  };
  return (
    <GlobalLayout>
      <Nav />
      <WorkSectionHeader
        workType='Product'
        workName='Big Bank Dashboard'
        what='A dashboard for Credit Services execs to make better decisions.'
        who='Fortune 50 Bank'
        role='Product Design'
      />
      <ShowcaseContainer>
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleOutline}
          src='../../images/work/bank/hifi1.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleOutline}
          src='../../images/work/bank/hifi2.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleOutline}
          src='../../images/work/bank/hifi3.png'
        />
      </ShowcaseContainer>
    </GlobalLayout>
  );
};

const ShowcaseContainer = styled.section`
  display: flex;
  flex-flow: column;
  padding: 3.33em 0;
  // justify-content: center;
`;

export default Bank;
